
$(document).ready(function() {
  var hHeight = $('.js-gnav').outerHeight();
  var urlHash = location.hash;
  var speed = 500;

  if(urlHash) {
    $('body,html').stop().scrollTop(0);
    setTimeout(function () {
      var target = $(urlHash);
      var position = target.offset().top - hHeight;
      $('body,html').stop().animate({scrollTop:position}, speed, 'swing');
    }, 1000);
  }

  $('a[href]').click(function(e) {
    var anchor = $(this),
    href = anchor.attr('href'),
    pagename = window.location.href;

    if(href.match(/#.*/) && $('body').attr('data-menu') == 'active'){
      $('body').attr('data-menu', '');
      $('body').css({'overflow':'visible','height':'auto','top':'0'});
    }

    pagename = pagename.replace(/#.*/,'');

    href = href.replace( pagename , '' );

    if( href.search(/^#/) >= 0 ){
      e.preventDefault();
      console.log(3);
      // eslint-disable-next-line quotes
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top - hHeight;
      $('html, body').animate({scrollTop:position}, speed, 'swing');

      location.hash = href;

      return false;
    }
  })

});
